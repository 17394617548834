.loading
  margin 60px auto
  text-align center

.loading__spinner
  margin 30px auto
  font-size 10px
  position relative
  text-indent -9999em
  border-top 2px solid #fff
  border-right 2px solid #fff
  border-bottom 2px solid #fff
  border-left 2px solid $brand_primary
  transform translate3d(0,0,0)
  animation load 0.5s infinite linear

.loading__spinner,
.loading__spinner:after
  border-radius 50%
  width 60px
  height 60px

@keyframes load {
  0% {
    transform rotate(0deg)
  }
  100% {
    transform rotate(360deg)
  }
}

.loading__spinner__small
  margin 5px
  font-size 10px
  position relative
  // text-indent -9999em
  border-top 2px solid #fff
  border-right 2px solid #fff
  border-bottom 2px solid #fff
  border-left 2px solid $brand_primary
  transform translate3d(0,0,0)
  animation load 0.5s infinite linear
  margin auto

.loading__spinner__small,
.loading__spinner__small:after
  border-radius 50%
  width 30px
  height 30px

.progress-indicator
  position sticky
  bottom 10px
  background-color #f5f5f5
  width max-content
  padding 1em 2em
  display flex
  left 50%
  transform translate(-50%, 0)
  justify-content center
  align-items center
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  border-bottom-right-radius: 5px
  border-bottom-left-radius: 5px
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px
  .text-center
    display flex
    gap 1em
