.dashboard
  > header
    text-align left
    display flex
    align-items center
    padding 60px 0 0 0

    h1
      color #A0A0A0
      font-weight 300
      font-size 50px
      flex 1
      margin-bottom 0

    + .course,
    + h2
      margin-top 60px

  h2
    font-size 30px
    font-weight 300
    color #A0A0A0

  .button
    margin-left 0

  .empty-state
    margin-top 18px
    max-width 600px
    font-size 19px

    p
      margin-bottom 8px

    p.small
      font-size 15px

    .button
      margin-top 18px
      margin-bottom 18px

  #user_courses
    margin-bottom 60px
    background #fff
    border 1px solid $border_lt
    box-shadow 0px 1px 2px rgba(0,0,0,.1)

    .table tr
      border-right none
      border-left none

    .empty
      text-align center

  .instructions
    margin 20px
    img
      width 40px

// making dashboard responsive
@media only screen and (max-width: 500px)
  .dashboard
    > header
      padding 20px 0 0 0
      display inline-block

      .tooltip-trigger
          position absolute
          top 1px
          left 140px

      h1
        font-size 20px
        font-weight 700
        margin-bottom 10px
    h2
      font-size 25px

    .instructions
      img
        width 17px
