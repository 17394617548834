.overview
  .primary
    +above(desktop)
      column(2/3, gutter: 1.8)
  .sidebar
    overflow-wrap break-word
    input, select
      width 100%
    .pop select
      width 190px
    +above(desktop)
      column(1/3)
  .section-header
    display flex
    margin-bottom 8px
    h3
      margin-right 20px
      min-width 0
      overflow-wrap break-word
    .controls
      position relative
      flex 1 0 auto

.milestones
  .module__data
    & > p
      color bayoux
      margin-bottom 8px
    hr
      margin 16px 0 8px 0
    &.completed
      opacity 0.5
  .markdown
    color body-color
  .section-header:last-child
    margin-bottom 0
    hr
      display none

.embed_stats
  small
    display: block
    color: green
    padding-top: 5px

.available-action
  margin-bottom 16px
  display grid
  text-align center

.admin-quick-actions
  .admin-status-panel
    margin 5px 25% 0 25%
    padding 5px
    background-color $warning-background
  
  .mark-as-review
    max-width 146px
    max-height 43px 
    padding 10px
