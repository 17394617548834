.campaign-path
  .campaign_main
    .Select
      top 10px

    .rails_editable-input
      display: none

  .button.dark.green,
  input.button.dark
    background-color sprout
    border-color sprout

  .campaign-details
    .form-group > label
      display: inline

    .button.plus
      display: none

  .campaign-details.rails_editable-editing
    label
      display: block

  .campaign-delete
    margin-top: 20px
    text-align: center
    button
      width: 80%

  .campaign-create
    margin-bottom: 20px
    margin-top: 20px
    text-align: center
    button
      width: 80%

  .campaign-organizers
    display: inline-block

  #edit_campaign_details
    .form-group
      margin-bottom: 0

    .campaign-use-dates
      display: none

      input
        width: auto

  .rails_editable-editing #edit_campaign_details
    .form-group
      margin-bottom: 8px

    .campaign-use-dates
      display: block

  .create-campaign-button
    margin-bottom: 50px

  .wizard .right
    width: 100% !important

  .campaign-template-description
    .tooltip
      color: white
      font-size: 12px
      min-width: 250px

  .campaign-new
    span
      display block
      margin-top 4px
      font-weight 600

.high-modal
  float right

