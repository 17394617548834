.form-content
  font-family body-font
  font-size 18px
  line-height 1.166666
  letter-spacing 1.29px

.description
  column(3/5)
  text-align left
  padding-bottom 30px
  p
    color white

.course-link
  margin-top 15px
  a
    color suit
    &:hover
      color zircon

.red
  color warning