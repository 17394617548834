.campaigns-actions,
.courses-actions
  margin: 10px 0 20px
  text-align: right

.explore-courses
  padding-bottom: 20px
  position relative
  input[type='text']
    border-color: hsl(0, 0%, 80%)
    border-radius: 4px
    width 100%
  input[type='text']::placeholder
    color: hsl(0, 0%, 50%)
  input[type='text']:hover
    border-color: #676EB4
  .checkbox-group
    padding: 10px 0px 20px 0px
    display: flex
    flex-direction: row
    align-items: center
    label
      user-select: none
    input[type="checkbox"]
      width: 15px
      height: 15px
      margin-right: 8px
