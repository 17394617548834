.confirm-modal
  background white
  padding 20px
  position fixed
  margin auto
  left 0
  right 0
  top 20%
  max-width 550px
  border 1px solid mischka
  border-radius 3px
  box-shadow 0 0 20px 0 rgba(0, 0, 0, .6)
  z-index 12

.confirm-modal-overlay
  background-color rgba(smoke, .4) !important
  z-index 100 !important

.confirm-explanation
  text-align left

#confirm-message
  text-align left