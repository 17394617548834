.language-picker
  text-align left
  width 120px
  z-index 1

  img
  	width 22px

  .language-picker__menu
    width 200px
    right 0px

@media only screen and (max-width: 920px)
  .language-picker
    position absolute
    top 15px
    right 15%
    padding-right 0px
    margin-top -5px
    margin-bottom -5px
