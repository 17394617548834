.basic-modal
  background white
  padding 20px
  position fixed
  margin auto
  left 0
  right 0
  top 20%
  max-width 800px
  max-height 80%
  border 1px solid mischka
  border-radius 3px
  box-shadow 0 0 20px 0 rgba(0, 0, 0, .6)
  overflow-y auto
  z-index 12

  .right
    text-align right
    float left

  .left
    text-align left
    float left

  .success
    font-size 150%

.basic-modal.left
  text-align left
