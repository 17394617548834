.articles-view
  .wrap-filters
    margin-top: -50px
    float right
    display flex
    flex-direction row
    flex-wrap nowrap
    align-items center
    b
      margin-right: 10px
    select
      font-size: 12px;
      font-weight: 600;
      height 40px
    .sort-container
      position unset
      height 40px
      +below(tablet)
        position absolute
      
  .see-more
    p
      padding-top :12px
      font-size: 15px;
    button
      float right
  
  .article-sort
    width: 60px
    .sorts
      background: url(../images/sort.svg) no-repeat 48% #fff;
      -webkit-appearance: none;
      text-indent: -200px;

  .article-diff-icons
    min-width: 144px;

  .article.tooltip-trigger
    display: inline-block

  .article.tooltip-trigger + button
    display block
  .deleted.article .rating
    border-color: rgb(248, 135, 135);
    p
      color: rgb(119, 119, 119);
  .deleted.article
    background-color: rgba(247, 58, 58, 0.05);
    .tooltip
      width: 200px

#articles-view-controls
  display grid
  grid-template-columns: auto max-content
  grid-template-rows: auto auto
  align-items: center
  justify-items: center
  grid-template-areas: 'pagination-controls see-more-btn' '. articles-shown-label'

  &.hidden-see-more-btn
    // when the see more button is hidden, we want to move the articles shown label up
    grid-template-areas: 'pagination-controls articles-shown-label'
    grid-template-rows: 1fr
  
  .pagination
    grid-area: pagination-controls
    justify-self: start
  
  .articles-see-more-btn
    grid-area: see-more-btn
  
  .articles-shown-label
    grid-area: articles-shown-label
    padding-top: 0px

// Hide the next li element for ReactPaginate 
.hide-next-btn li.next 
  display: none

// Hide the previous  li element for ReactPaginate
.hide-prev-btn li.previous 
  display: none
