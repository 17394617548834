&.timeline
  padding 10px
  a
    cursor pointer
  > li
    margin 0
  .view-all
    border-bottom 0
    margin-top 20px
.week
  display block
  margin 0 0 30px
  position relative
  &:first-child
    margin-top 20px
  > div
    p
      color $text
      font-size 34px
      font-weight 300
      margin-bottom 12px
    > .button
      float right
  .list
    margin-top 15px
.week, .block
  +.view-all
    border-bottom 0
.block +.view-all
  margin-top 20px
.block__edit-button-container
  overflow hidden
  position absolute
  height 40px
  width 100px
  top 15px
  right 15px
  z-index 0
.block__edit-block
  position absolute
  right 0
  z-index 9
  box-shadow 0 0 0 9px rgba(255,255,255,0.8)
.block__this-week-button
  position absolute
  right 0
  box-shadow 0 0 0 9px rgba(255,255,255,0.8)
.block
  background-color white
  border 1px solid zircon
  left 0
  margin 0 0 20px 0
  padding 18px 20px 20px
  position relative
  width 100%
  //transition all .25s ease-out
  box-shadow 0 3px 4px rgba(0,0,0,0.1)
  &:first-child
    border-width 1px
  &:last-child
    margin-bottom 0
  &.dragging
    opacity 0
    // transform scale(1.05)
  &.block-leave-active
    opacity 0
    transform scale(0.5)
  textarea
    width 100%
  input[type='text'], input[type='date'], select
    margin-right 10px
    &.title
      width 100%
  input[type='checkbox']
    margin 20px 5px
  .button
    clear right
    float right
    margin 0
    &.left
      float left
  .block-title
    color $brand_primary
    font-weight 400
    flex 1
    margin-right 10px

.block__block-content
  color $text_dk

.block.block--orderable
  display flex
  align-items center
  cursor move

  > h4
    margin-bottom 0
    margin-right auto

  > p
    margin-bottom 0
    margin-right 16px
    font-size 14px

  > button
    padding 0
    margin-left 10px
    line-height 1
    border-radius 2px
    overflow hidden
    width 26px
    height 26px
    font-size 25px
    .icon
      margin-left 0

.shrink-enter
  position absolute
  opacity 0.01
  transition all 250ms ease-in-out
  transform scale(.8, .5)
  &.shrink-enter-active
    opacity 1
    transform scale(1, 1)
.shrink-exit
  transform scale(1, 1)
  opacity 1
  transition all 250ms ease-in-out
  &.shrink-exit-active
    opacity 0.01
    transform scale(.8, .5)


.block__block-type
  color $text_dk
  font-size 14px
  font-weight 300
  position relative
  -webkit-font-smoothing auto
  top 2px
  &.editable
    margin-right 80px
  .react-select_dropdown
    min-width 135px

.margin-bottom
  margin-bottom: 10px

.week__week-header
  overflow hidden
  .text-input-component__value
    color $text_med
    font-size 15px
    position relative
    bottom 6px
    font-weight 400
    margin-left 10px
  input.title
    position relative
    bottom 5px

.week__week-add-delete
  margin-top 16px
  button
    cursor pointer
    margin-left 25px
    text-decoration none
    color $text_med
    &:hover
      color $brand_primary
  i
    position relative
    top 2px
    margin-left 2px

button.week__delete-week
  &:after
    position relative
    top 2px
  &:hover
    color $warning_text

button.week__add-block
  color $brand_primary
  &:after
    font-size 12px
  &:hover
    text-decoration underline

.week__delete-week:after, .week__add-block:after
  font-family 'icons'
  font-size 14px
  padding-left 2px

.week__week-dates
  position relative
  margin-bottom 12px
  color $text_med

.week__no-activity
  text-align center
  h1
    padding 40px 0 30px 0
    font-weight 300
    font-size 24px
    color $text_med_header

.week__no-activity .week__no-activity__get-started
  background $bg_med
  font-size 17px
  font-weight 400
  -webkit-font-smoothing auto
  text-align center
  height 150px
  display flex
  align-items center
  justify-content center
.empty-week-clickable
  cursor pointer
  color $brand_primary
  text-decoration none
  &:hover
    text-decoration underline

.week__week-header .week-index
  font-family $header-font
  font-size 34px
  font-weight 300
  color $text_med
  -webkit-font-smoothing auto
  margin-bottom 0px
  &.week-title-input
    margin-bottom 12px

.week-range
  font-family $header-font
  font-size 20px
  font-weight 300
  -webkit-font-smoothing auto
  margin-bottom 0px
  &.week-title-input
    margin-bottom 12px

.week__week-header .text-input-component__value:hover
  background url("../images/pencil.svg") right center no-repeat
  padding-right 25px

.gradeable
  overflow hidden

.block__training-modules
  margin-bottom 1.5em

.block__training-modules .table
  table-layout fixed

.block__training-modules h4,
h4.timeline-exercise,
h4.training-module
  margin-bottom 8px
  font-weight 400

.block__training-modules-table__module-name
  width auto
  overflow hidden
  white-space nowrap
  text-overflow ellipsis

.block__training-modules-table__module-progress
  width 28%
.block__training-modules-table__module-link
  width 18%
  text-align right
  a
    text-decoration none
    &:hover
      text-decoration underline
  i
    font-size 13px
  +below(tablet)
    width 28%

.block__training-modules-table__module-name-header
  width 42.5%
.block__training-modules-table__status-header
  width 40%



.training-module, .student-training-module
  .unread, .overdue
    color warning
  .complete, .completed
    color $success_text
  small.due-date
    margin-left 5px

.student-training-module
  td:first-child
    width 26.5%


.training-module__link
  margin-top 0.75em
  i
    font-size 14px
    position relative
    top 1px
    left 1px

.timeline-module__progress-complete
  color $success_text
  font-weight bold

.timeline__content
  column(12/12)
  +below(tablet)
    display flex
    flex-direction: column-reverse

.timeline__week-nav
  margin-top 16px
  column(3/12, offset: 1/12)
  +below(tablet)
    margin-top 0px
    width: fit-content;

.timeline__weeks, .timeline__grading-container
  column(8/12)
  +below(tablet)
    column(12/12)

.grading__grading-container
  clear both
  position relative

.timeline__anchor
  display inline-block
  padding-top 60px
  margin-top -60px

.timeline__week-nav
  h5
    font-weight 400
    margin-bottom 6px
    line-height 1
    font-size 16px
  .muted
    font-size 13px
    line-height 1.4
    margin-bottom 16px
  .panel
    list-style none
    padding 8px
    background #FFF
    border 1px solid $border_med
    height auto
    max-height calc(100vh - 220px)
    min-height 122px
    overflow-y auto
  ol
    list-style none
  li
    border-bottom 1px solid $border_lt
    margin-left 0
    padding 10px 8px 2px 8px
    &.is-current
      a
        font-weight bold
      span
        color $text
      &:before
        content '► '
        float left
        width 0
        position relative
        right 17px
        color $border_med
        font-size 12px
        top 3px
        height 0px
  a
    color $text
    font-size 15px
    text-decoration none
    width 75px
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    display inline-block
    &.no-nav-dates
      width 200px
  span
    color $text_med

.week-nav__action.week-nav__link
  color $brand_primary
  display block
  padding-left 8px
  cursor pointer
  width 100%
  &:hover
    text-decoration underline

.timeline__week-nav li:not(.week-nav__item)
  padding-bottom 8px
  .week-nav__link
    padding-left 0
    &.disabled
      color rgba(30,30,30,0.4)
      cursor text
      &:hover
        text-decoration none

.timeline__week-nav li .week-nav__add-week
  color $brand_primary
  cursor pointer

.affix
  position fixed
  +below(tablet)
    position relative

.timeline__week-nav .affix
  top $nav_height
  width 250px
  +below(tablet)
    top 0px
    width 100%

.block__default-due-date
  color $brand_primary
  &:before
    content ' - '


// Editing
.block__edit-container
  display flex
.delete-block-container
  width 100%
  border-top 1px solid $border_lt
  padding 20px 0 0
  text-align center
  margin-top 20px
  button
    color $warning_text
    background url("../images/trash-red.svg") left center no-repeat
    padding-left 25px

.editable .block__default-due-date
  display none

.block__read__due-date
  display inline
  color $brand_primary

li.block.editable
  margin-bottom 20px
  &:hover
    box-shadow 0 0 15px 0 rgba(0, 0, 0, .2)
  .drag-handle
    display block
  input.editable
    width 300px
  .block-title
    line-height inherit
    font-weight inherit
    font-size inherit
    letter-spacing inherit
    margin-right 20px
  .block__edit-block
    visibility hidden
  .block__due-date-spacer
    display none
  .block__read__due-date
    display none
  .block__block-type
    margin-right 0
    top 0



p.graded
  margin 0
  position relative
  bottom 5px

// Assignment block type changes
// layout slightly
.block-kind-1
  p.graded
    bottom 0
    top 21px
  .block__block-due-date
    clear both
    margin-right 25px

.block__block-actions
  margin-bottom 12px
  position relative
  z-index 10
  button.button
    margin-left 30px
    cursor pointer
  span
    margin-top 8px
    cursor pointer
    color $brand_primary

.timeline-ctas
  margin 1em 0
  +below(tablet)
    margin 0px 0px 15px 0px

  .button
    margin-bottom 6px

    &:last-child
      margin-bottom 0

  .button.timeline__add-assignment
    display block
    margin-bottom 1em

.timeline-ctas__cancel
  position relative
  top 5px

.Select-menu-outer
  box-shadow 0px 3px 5px rgba(0,0,0,.35)

.timeline-warning
  padding 10px
  border solid $warning_text 3px

a.timeline-exercise
  padding 6px 12px
  margin-bottom 12px
  width 100%
  color #6a6a6a
  display table
  border 1px solid #ddd
  text-decoration none
  &:hover
    border solid 1px $brand_primary
  &:after
    font-family 'icons'
    color $brand_primary
    content " \EA0A"
    float right

a.training-module
  padding 6px 12px
  margin-bottom 12px
  width 100%
  color #6a6a6a
  display table
  border 1px solid #ddd
  text-decoration none
  display flex
  justify-content space-between
  &:hover
    border solid 1px $brand_primary

.icon-rt_arrow_purple_training_module
  background url("../images/rt_arrow_icon_purple.svg") center no-repeat
  padding 8.5px

a.handout-link
  margin-right 8px
  color #6a6a6a
  border solid 1px #ddd
  border-radius 3px
  text-decoration none
  padding 5px
  &:hover, &:focus
    border solid 1px $brand_primary