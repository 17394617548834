/* /////////////////////////////////////////////////////

WikiEduDashboard styles
Author: team@wintr.us

///////////////////////////////////////////////////// */
@import "_reset"
@import "_shared"
@import "_icons"
@import "_utils"
@import "_fonts"
@import "_layout"
@import "_base"
@import "modules/*"
@import "modules/_nav_news"
@import "home"