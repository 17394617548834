.table tr.assignment td
  border none
  padding 10px 20px
  .title
    overflow-wrap anywhere

.table tr.assignment-section-header td
  border none
  padding-bottom 10px

tr.assignment-section-header h3
  font-size 16px
  margin-bottom 0

.assignment-popover
  tr.assignment
    td
      width 100%
      display flex
      a
        max-width 500px
        display block
        white-space nowrap
        overflow hidden
        text-overflow ellipsis

.find-articles-section
  background athens
  border-top 1px solid darken(athens, 10%)
  text-align center
  td
    display block
    width 100%

.select-container
  display flex
  flex-direction column

.edit_sandbox_url_input
  min-width: 30rem;