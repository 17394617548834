.consent-banner
  animation fadein 1s

  a
    color white
  button:hover
    background white !important

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
