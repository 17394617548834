/* /////////////////////////////////////////////////////

BASE STYLES
Only single elements. No classes or ids.

///////////////////////////////////////////////////// */


*
  box-sizing border-box

html
  -webkit-text-size-adjust 100%
  -webkit-font-smoothing antialiased
  height 100%
  font-size 0.938em

body
  line-height 1.56
  background-color smoke
  font-family body-font
  width 100%
  height 100%
  color body-color
  &.modal-open
    overflow hidden

h1
h2
h3
h4
h5
  letter-spacing .02em
  color bayoux
  margin 0 0 16px 0
  line-height 1.2
  font-weight 600

h1
  margin-top 0
  font-size 2.441em

h2
  font-size 1.953em

h3
  font-size 1.563em

h4
  font-size 1.25em

h5
  font-size 1em

p
  margin-bottom 16px
  -webkit-hyphens auto
  -moz-hyphens auto
  -ms-hyphens auto
  hyphens auto

small
  font-size 0.8em
  color rgba(0,0,0,.35)
  display inline-block
  vertical-align middle

p
  &:first-child
    margin-top 0
  &:last-child
    margin-bottom 0

i
  font-style italic

li
  margin-left 20px

  &:first-child
    margin-top 0

ul
  list-style disc
  margin-bottom 16px
  &:last-child
    margin-bottom 0

ol
  list-style decimal
  margin-bottom 16px
  &:last-child
    margin-bottom 0


blockquote
  margin-bottom 16px
  border-left: 4px solid #eee
  padding 0 1em

code
  display inline-block
  font-size 0.8em
  font-family Courier, monospace
  background-color rgba(0,0,0,0.04)
  border-radius 3px
  line-height 1.4
  padding .2em

pre > code
  display block
  font-size 0.8em
  padding 1em 1em
  margin-bottom 16px
  white-space pre-wrap

hr
  border 1px solid mercury
  border-width 1px 0 0
  height 1px
  clear both
  margin-bottom 16px

a
  color bayoux
  text-decoration underline
  &:hover
    color darken(bayoux, 10%)
  &:active
    color bayoux
  &.inline
    color inherit

em
  font-style italic

select
  font-family body-font
  font-size 15px

b, strong
  font-weight 600

a > i
  margin-left 3px
