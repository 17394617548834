.ticket-notifications
  position sticky
  top 55px
  z-index 2
.ticket-dashboard
  td.sender
    word-break break-all
  .reply-header
    h4
      color $text
      margin-bottom 0.5rem
    h6.cc
      color grey
      font-size 12px
      margin-bottom 1rem
      text-transform uppercase
      span
        font-weight bold
  .message-body
    white-space pre-line
    overflow-x auto
  .messages
    span(3/4)
    p
      margin-bottom 0
  .sidebar
    padding 0 2rem
    position sticky
    top 75px
    span(1/4)
    section
      padding 0.5rem 0
      &:first-child
        padding-top 0
  .bold
    font-weight 600
  .open
    color sprout
  .bg-white
    background white
  .reply-details
    background athens
    border 1px solid zircon
    border-top none
    display flex
    margin-bottom 1rem
    padding 0.5rem 20px
    span
      align-items center
      display flex
      flex 1
      font-size 0.9rem
      p
        width 100%
    .status-icon
      border-radius 50%
      height 25px
      margin-left auto
      padding 8px
      width 25px
      &.check
        background blue-violet
      &.minus
        background warning
  .tickets-reply
    margin-top 2rem
    h3
      margin-bottom 4px
    .mce-content-body
      overflow-x auto
    .button.plus
      position relative
      top -3px
    .cc-fields
      align-items baseline
      display flex
      label
        font-weight bold
        width 35px
      .form-group
        flex 1
      input.cc
        background none
        border none
        border-bottom inherit
        width 100%
        &::placeholder
          font-style: italic
  .tickets-note
    width 85%
    margin-left auto
    .reply-header
      background white
      border-color blue-violet
    .reply-details
      background lighten(blue-violet, 10)
      border-color blue-violet
      color white
  .tickets-pagination
    margin 1rem 0
    text-align center
    li
      color blue-violet
      display inline-block
      font-weight bold
      margin-left 7px
      button
        padding 3px
        &.selected
          color dove
          border-bottom 2px solid dove
  .note-heading
    display flex
    img
      object-fit cover
      width: 40px
      margin-top -10px
      cursor pointer   
      margin-left auto
      
          