.module.my-exercises
  h3.completed
    margin 0
  h3 small
    font-size 14px
    font-weight normal
    margin-left 15px
  .header
    display flex
    padding 0
    .resources-link
      flex 1
      margin-top 5px
      text-align right