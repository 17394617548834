.article-viewer
  display grid
  grid-template-rows max-content 1fr max-content
  max-height calc(100vh - 140px)
  background-color white
  position fixed
  top 70px
  /*@noflip*/ left 50%
  width 100%
  max-width 1200px
  z-index 10
  border 1px solid mischka
  border-radius 3px
  box-shadow 0 0 20px 0 rgba(0, 0, 0, .6)
  transform translateX(-50%)
  overflow auto

#icon-article-viewer-desc
  display none

#popup-style
  position: fixed
  top: calc(100% - 80px)
  left: 0
  border: 0
  background: #676eb4
  width: 100%

.article-header
  border none !important
  padding 5px 10px 5px 10px
  padding-left 30px
  border-bottom 1px solid mischka !important

.tooltip
  position: relative
  color: white

.tooltip-indicator-article-viewer
  background: url('../images/circle_q_mark_icons.svg') center no-repeat
  padding: 8.5px;


.article-alert
  align-items flex-start
  display flex
  margin 10px 20px
  p
    margin-bottom 10px
  .learn-more
    flex 2
  .submit-alert
    flex 3
  .learn-more, .submit-alert
    background #EEE
    border-radius 10px
    margin 10px 15px
    padding 15px
    #submit-bad-work-alert
      margin-bottom 10px
      width 100%

.article-footer
  border-top 1px solid mischka !important
  font-size 90%
  max-height: calc((100vh - 140px) * 0.2)
  overflow-y auto
  overflow-x hidden
  width 100%
  div
    float left

.authorship-loading
  background url('../images/loader.gif') no-repeat 50% white

.authorship-status-failed
  color $warning_text

.user-legend-wrap
  width 90%
  background white
  
.article-viewer-legend
  margin 7px
  border-radius 15px
  display flex
  align-items center

.user-legend-name
  padding 0px 0px 0px 10px

.user-legend-hover:hover
  background-color rgba(0, 0, 0, 0.2)
  border-radius 15px

.user-highlight-1
  background-color #e5ddf2
.user-highlight-2
  background-color #f7b4b6
.user-highlight-3
  background-color #f8f2e0
.user-highlight-4
  background-color #bbdefb
.user-highlight-5
  background-color #b2dfdb
.user-highlight-6
  background-color #d7ccc8
.user-highlight-7
  background-color #fce4ec
.user-highlight-8
  background-color #e0e0e0
.user-highlight-9
  background-color #ffe082
.user-highlight-10
  background-color #b0bec5
.user-highlight-11
  background-color #b9f6ca
.user-highlight-12
  background-color #84ffff
.user-highlight-13
  background-color #64ffda
.user-highlight-14
  background-color #ff9e80
.user-highlight-15
  background-color #ea80fc

.user-highlight-16
  background-color #87a286
.user-highlight-17
  background-color #f49b65
.user-highlight-18
  background-color #cd94db
.user-highlight-19
  background-color #b5f4e7
.user-highlight-20
  background-color #b8b5f4
.user-highlight-21
  background-color #c4babd
.user-highlight-22
  background-color #d35fb0
.user-highlight-23
  background-color #f25a94
.user-highlight-24
  background-color #b026c0

.article-viewer-title
  font-size 200%
  color bayoux

.article-scrollbox
  max-height calc(95vh \- 200px)
  overflow-y scroll
  overflow-x hidden

.parsed-article
  margin 30px
  color #4a4a4a
  a
   color #0645ad
   text-decoration none

  h2
  h3
  h4
    color black
  #toc
    display table
    border 1px solid border black !important
    background-color #f8f9fa
    font-size 85%
    padding 10px
    ul
      list-style none
      color black
    #toctitle
      text-align center
      font-size 60%

  .tright
    clear right
    float right
    margin .5em 0 1.3em 1.4em
  .thumbinner
    border 1px solid #c8ccd1 !important
    padding 3px
    background #f8f9fa
  .mw-editsection
    display none
  .infobox
    float right
    clear right
    font-size 88%
    border 1px solid #a2a9b1 !important
    border-spacing 3px
    margin 0.5em 0 0.5em 1em
    padding 0.2em
    line-height 1.5em
  .navbox
    display none

  sup
    vertical-align super
    font-size smaller

.wikibase-statementgroupview-property-label
	padding 30px 10px
	width 100%
	margin-right 70px
.wikibase-statementgroupview-property
	background-color #eaecf0
	width 15em
.wikibase-statementgroupview
	background-color #fff
	border 1px solid #c8ccd1
	margin-bottom 15px
	display flex
.wb-normal,
.wb-preferred
	border-bottom 1px solid #eaecf0
	width 100%
	padding 15px 25px
.wikibase-statementlistview
	width -webkit-fill-available
	overflow auto
.wikibase-statementview-mainsnak-container
	margin-right 30px
.wikibase-snakview
	display flex
.wikibase-statementview-qualifiers
	.wikibase-snakview-value
		margin-left 12em
.wikibase-referenceview-listview
	padding 20px 10px
	background-color #f8f9fa
	overflow-x auto
	div
		&.wikibase-snakview
			padding 10px 5px 5px 10px
.wikibase-referenceview
	.wikibase-snaklistview-listview
		.wikibase-snakview-value-container
			margin-left 10%
			position relative
		.wikibase-snakview-property
			width 12em
			font-size 90%
wikibase-snakview-value
	-ms-word-break break-all
	word-break break-all
	word-break break-word
	-webkit-hyphens auto
	-moz-hyphens auto
	-ms-hyphens auto
	hyphens auto
.wikibase-sitelinkgroupview
	padding 10px
	border 1px solid #c8ccd1
	margin-bottom 10px
	margin-right 10px
	width 20%
	min-width 22em
	overflow auto
.wikibase-entityview
	display flex
	flex-wrap wrap
.wikibase-entityview-main
	width 75%
	max-width 760px
	margin-right 20px
	width 95%
	max-width unset
	margin-right 20px
.wb-sitelinks-heading
	font-size 20px
	& > span
		font-weight 500
		font-size 80%
		margin-left 3px
@media screen and (max-width: 1300px)
  .wikibase-entityview
    display flex
    flex-wrap wrap
    flex-direction row
  .wikibase-listview
    & > .wikibase-sitelinkgroupview
      float left

