.vega-graph
  background-color white
  padding 10px
  position fixed
  z-index 1
  top 70px
  left 50%
  min-width 620px
  min-height 440px
  max-width 1200px
  max-height 90vh
  border 1px solid mischka
  border-radius 3px
  box-shadow 0 0 20px 0 rgba(0, 0, 0, .6)
  transform translateX(-50%)

  .radio-row
    list-style-type: none
    overflow: hidden
    margin 5px 0 0 60px
    font-weight: bold
    font-size: 17px

    .input-row
       display: inline-block
       margin-right: 50px

.ores-plot
  padding-top 10px
  padding-bottom 50px