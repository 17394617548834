
/* Variables for the home page. These should make their way into main.styl soon. */
$brand-primary = #676EB4
$body-color-dark = #2C2C2C
$body_color = #6A6A6A
$body_font = "Open Sans", "Source Sans Pro", arial, sans-serif
$heading_font = $body_font
$h1_font = "Source Sans Pro", "Open Sans", arial, sans-serif

body.print-article-viewer
  print-color-adjust exact
  -webkit-print-color-adjust exact
  display flex
  flex-direction: column
  align-items center
  background-color white
  .user-legend-wrap
    .user-legend
      margin 0
    background transparent
    display flex
    flex-wrap wrap
    gap 5px
    justify-content end
    width max-content
    :nth-child(1)
      display grid
      place-items center
  .parsed-article
    margin-top 0
  .header-print-article-viewer
    display flex
    justify-content space-between
    align-items center
    padding 0 30px
    width 100%


body.home
  color $body_color
  font-family $body_font
  .notification
    text-align center

  +below(desktop)
    .button-container
      position relative
      border 1px solid white
      border-radius 3px

  /* Base button */
  .button
    background #fff
    border none
    border-radius 3px
    color: $brand-primary
    font-weight 600
    margin-left 0

    & + .button {
      margin-left 20px
    }

    &:hover, &:focus
      background-image linear-gradient(to bottom, #fff, #e0e0e0)

  /* Ghost button */
  .button.button-ghost
    background transparent
    border 1px solid $brand-primary

    &:hover, &:focus
      color #fff
      background $brand-primary

  /* Ghost button for inverted backgrounds */
  .button.button-ghost.button-inverse
    border none
    box-shadow inset 0 0 0 1px #fff
    color #fff

    &:hover, &:focus
      color $brand-primary
      background #fff

  /* Help icon button */
  .help
    border 1px solid #fff
    color $brand-primary
    background-color #fff
    border-radius: 50%
    font-size 16px
    font-weight 600
    display inline-block
    width 26px
    height 26px
    line-height 24px
    text-decoration none
    text-align center
    transition all 62ms ease-in-out
    padding-left 1px
    margin-left 20px
    position relative

    &:after
      transition all 125ms ease-in-out
      opacity 0
      transform translate(-50%, 0) scale(.9, .9)
      content attr(data-title)
      visibility hidden
      font-size 13px
      font-weight 400
      background #fff
      border-radius 2px
      box-shadow 0px 1px 2px rgba(0,0,0,.1)
      color $body_color
      text-align left
      line-height 1.4
      padding 15px
      position absolute
      z-index 98
      width 280px
      left 50%
      top 50%
      pointer-events none

      +below(desktop)
        left -600%

    &:hover, &:focus

      &:after
        visibility visible
        opacity 1
        transform translate(-50%, 5px) scale(1,1)


  /* Home page hero */
  .hero
    .container
      +above(desktop)
        padding-bottom 100px
        padding-top 80px

    .button
      +below(desktop)
        margin 24px 40px 24px 15px
        display block
        text-align center

    .help
      +below(desktop)
        margin 0 auto
        display block
        position absolute
        top 6px
        right 6px


    h1
      color #fff
      margin-bottom 80px

      +below(desktop)
        margin-bottom 40px
        text-align center


  /* Basic sections and variants */
  section
    .container
      padding 60px 30px 60px 30px

      +below(desktop)
        padding 60px 10px 20px 10px

    &.light
      background-color #fff

    &.dark
      background-color $brand-primary
      background-image url("../images/waves.svg")
      background-repeat no-repeat
      background-position center 75%
      background-size cover

      &, h1, h2, h3
        color #fff

    h1, .h1
      font-family $h1_font
      font-size 32px
      font-weight 300
      line-height 1.4
      color $brand-primary
      max-width 100%

      +above(desktop)
        font-size 54px

    h2, .h2
      line-height 1.5
      font-family $heading_font
      font-size 24px
      font-weight 300
      color $body-color-dark
      margin 0 auto 20px auto

      +above(desktop)
        font-size 30px

    h3, .h3
      color $brand-primary
      font-size 16px
      font-family $heading_font

      +above(desktop)
        font-size 20px

    ul
      font-size 21px
      margin-bottom 20px
      li
        margin-bottom 10px


  /* 2 column grid */
  .col
    margin-top 40px

    +above(desktop)
      column(1/2)


  /* Total stats */
  .stats-total
    .container
      padding-bottom 80px
    h2
      margin-bottom 60px
    h3
      font-family: $h1_font
      font-weight 300
      -webkit-font-smoothing auto
      font-size 22px
      margin-bottom 0px
      +above(desktop)
        margin-bottom -10px
        font-size 25px
    h4
      color #fff
      font-weight 600
      font-size 24px
      +above(desktop)
        font-size 130px

    p
      font-size 18px
      line-height 1.3
      +above(desktop)
        font-size 21px

    .container > div:last-child
      .col
        h4
          +above(desktop)
            margin-top 10px
            font-size 75px

  .universities
    h2
      text-align center
      max-width 85%
      margin-bottom 60px

    .logos
      display flex
      flex-direction row
      flex-wrap wrap
      justify-content space-around
      align-items center

      > img
        margin 30px 30px 0 30px

        +below(desktop)
          margin 15px 15px 0 15px
          max-width 30%

.supporters
  padding-bottom 30px

  .logo-column
    width 50%
    height 300px
    float left
    display flex
    justify-content: center
    align-items: center

    img
      max-width 90%
      vertical-align middle