@keyframes STAT-CHANGE
  0% {
    opacity 0
    transform scale(1.4)
  }
  50% {
    opacity 1
  }
  100% {
    opacity 1
  }

.main-page
  h1
    font-weight 300
    margin-bottom 0

.stat-display
  display inline-block
  width 100%
  margin 40px 0 20px 0
  display flex
  justify-content space-between
  flex-flow wrap

  .stat-display__stat
    margin 0 20px 20px 20px
    text-align center
    width calc(50% - 40px)

    +above(desktop)
      margin 0 0 20px 0
      width (1/8*100)%

    .stat-display__value
      font-family inherit
      color $brand_primary
      font-size 38px
      margin 0
      font-weight 300

      &.stat-change
        animation STAT-CHANGE 3s;

      img
        max-width 13%
        position absolute
        top 9px

    .stat-display__data
      font-family inherit
      color $brand_primary
      font-size 1em
      margin 0
      font-weight 300
      padding 10px 5% 1px 5%

    small
      color #666
      font-size 1em
      line-height 1

  .tooltip
    left -15%
    width 100%
    &:before
      left 50%

.overview-stats-tabs-container
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 0px 20px 20px 20px

  .tabs-container
    &.hide
      display: none
    
    padding: 20px 0px 0px 0px
    display: inline-flex
    width: 100%
    border-bottom: 2px solid $brand_primary
    overflow-x: auto
    overflow-y: clip

    .tab
      padding: 8px 12px 8px 12px
      min-width: 200px
      cursor pointer
      margin-right: 20px
      position: relative
      top: 2px

      p
        font-size: 13px
        color: #666

      &.active
        background-color: $brand_primary
        border: none
        p
          color: #fff
          font-weight: 600

  .content-container
    padding: 0px

    .title
      font-size 20px
      font-weight 500
      line-height 55px
      -webkit-font-smoothing auto
      margin 0

.wikidata-stats-container
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 0px 20px 10px 20px;
  margin 0 0 20px 0

  &.course-overview
    border: none
    padding: 0px

  .wikidata-stats-title
    font-size 20px
    font-weight 500
    line-height 55px
    -webkit-font-smoothing auto
    margin 0

  .wikidata-display
    width 100%
    display flex
    justify-content space-between
    flex-flow wrap

    .stat-display__row
      width: 25%
      padding: 0 15px 18px 0

      &.double-row
        width: 50%

      .stat-display__value-group
        justify-content left
        gap 10px

        &.double
          width: 50%
          float: left

    .stat-display__stat
          width: 100%
          line-height 1.4
          padding 2px 0
          display inline-block

          .stat-display__value-small
            font-family inherit
            color $brand_primary
            font-size 22px
            font-weight 300
            padding-left 15px
            float left
            margin-right 10px
            min-width 30%

          small
            color #666
            font-size 15px
            line-height 1
            vertical-align bottom

          img
            width 8px
            position absolute
            top 5px

.user_stats
  .stat-display
    margin 0 0 30px 0

  #instructor-profile-stats
    display block

    .stat-display
      justify-content initial
      margin 0 0 50px 0

      .active-button
        background-color #fff
        border-color $brand_primary

      .stat-display__stat
        margin 0 20px 0 20px

.stats-label
  padding-left 18px
  margin-bottom 5px
  color #666
  background-color: #F5F5F5

.single-stat
  margin-top 1.5rem

@media only screen and (max-width: 919px)
  .stat-display
    .stat-display__value
      img
        display none
