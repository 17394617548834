.gallery-view
  display flex
  flex-wrap wrap
  width 100%
  padding 3px
  margin-bottom 50px
  overflow hidden
  .upload
    position relative
    margin 2px
    cursor pointer

  .upload img
    object-fit cover
    vertical-align bottom
    width 100%

  .upload .info
    display flex
    flex-direction column
    justify-content center
    height 100%
    width 100%
    position absolute
    bottom 2px
    background rgba(0, 0, 0, 0.5)
    color rgba(255, 255, 255, 0.9)
    overflow hidden

  .info p
    margin 2px 20px
    text-shadow 0px 4px 3px rgba(0,0,0,0.4),
               0px 8px 13px rgba(0,0,0,0.1),
               0px 18px 23px rgba(0,0,0,0.1)

  .info p.usage
    font-size 1.5em
    color rgb(190, 151, 225)

  .upload .info
    display none

  .upload:hover .info
    display flex

  .info p a
    color rgb(255, 255, 255)

  .none
    display flex
    width 100%
    height 49px
    background-color rgb(255, 255, 255)
    border 1px solid rgb(221, 221, 221)

  .none p
    margin auto

.gallery::after
  content ''
  flex-grow 999999999

.list-view
  cursor pointer
  img
    min-width 250px
    max-width 250px
  .credit-loading
    min-width 25px

.tile-view
  .tile-container
    width 25%
    height 350px
    display inline-block
    text-align center
    vertical-align top
    margin-top 10px

    .tile
      margin 5px
      padding 10px
      background white
      border 1px solid #ddd
      height 100%
      border-radius 3px
      cursor pointer

    img
      height 150px

    .info
      text-align left

.view-buttons
  +above(desktop)
    position absolute
    top 20px
    right 80px
  +below(desktop)
    width 100%
    margin-top -10px
    margin-bottom 7px
  button
    padding 0
    width 40px
    height 40px
    border-radius 50%


#uploads .Select
  width 33%

.upload-viewer
  width 50%
  box-shadow 0 0 20px 0 rgba(0, 0, 0, .6)
  text-align left
  padding 0

  .modal-header
    border-bottom 1px solid #ddd
    padding 10px 20px 0px 20px

  .modal-body
    display grid
    grid-template-columns 1fr 1fr
    grid-column-gap 3rem
    padding 20px

  .modal-body a
    word-break break-all

    h4
      margin 2rem 0 1rem 0

    img
      width 100%;

    table
      width 100%

    table, td, th
      word-wrap break-word
      padding 60px

      .bg-grey, th
        background-color #f5f5f5
        font-weight 900

      .text-right
        text-align right

  .modal-footer
    height 50px
    border-top 1px solid #ddd

    .upload-viewer-button
      margin 10px

.view-file-details
  border 1px solid #ddd

  .row-details
    border-bottom 1px solid #ddd
    padding 10px
