nav.top-nav
  top 0
  z-index 100
  background-color white
  border-bottom 1px solid rgba(0,0,0,.1)
  width 100%
  font-size 15px
  -webkit-font-smoothing antialiased
  line-height 1

  &.fluid
    .container
      max-width none
      padding-left 15px
      padding-right 15px

  ul
    list-style none
    margin-bottom 0
    padding 0

  li > a
    text-decoration none
    color inherit
    padding 25px 0 15px 0
    font-size 15px
    &:hover, &.active
      color $brand_primary
      border-bottom 3px solid $brand_primary


  .container
    height $nav_height
    display flex
    align-items center
    justify-content space-around

  .notifications
    a
      display inline-block
      opacity 0.6
      width 35px
      &:hover
        animation ring 2s ease-in-out
        border none
    .bubble
      display inline-block
      position relative
      padding 4px
      height 4px
      width 4px
      border-radius 50%
      top -20px
      left -10px
      &.red
        background warning
        &:after
          animation pulse 3s ease-in-out infinite
          content ''
          position relative
          top -5px
          left 5px
          width 10px
          height 10px
          border-radius 50%
          background warning
          float right

@keyframes pulse
  20%
    transform: scale(1.8)
    opacity: 0
  100%
    transform: scale(1.8)
    opacity: 0

@keyframes ring
  0%
    transform rotate(0deg)
  10%
    transform rotate(35deg)
  20%
    transform rotate(-30deg)
  30%
    transform rotate(25deg)
  40%
    transform rotate(-20deg)
  50%
    transform rotate(15deg)
  60%
    transform rotate(-10deg)
  70%
    transform rotate(5deg)
  80%
    transform rotate(0deg)

.top-nav__faq-search ~ .top-nav__login-links
  margin-left 0

.top-nav__login-links
  display flex
  justify-content flex-end
  align-items: center
  margin-left auto
  b
    +below(desktop)
      display none
  i
    margin-right 0px
    margin-bottom: 0px;
    font-size 16px
    vertical-align middle

  .expand
    overflow hidden
    display inline-block
    width 0
    transition all 250ms ease-in-out
    opacity 0
    vertical-align top
    white-space nowrap
    text-align left

    +below(desktop)
      display none

  li > a
    color $text
    border-bottom 0
    +below(desktop)
      margin-left 0

    &:hover
      color $brand_primary
      .expand
        opacity 1
        width 110px

  span
    display inherit
    align-items center

.top-nav__main-links
  display flex
  justify-content flex-start
  margin 0
  padding 0
  li
    margin-left 25px
  a
    margin 0
  span
    display inherit
.top-nav__site-logo
  line-height 0
  a
    display inline-block
    margin 0
    border none

  img
    width 35px
    height 35px

.top-nav__faq-search, .campaign-nav__search
  margin-left auto
  text-align center
  border-left 1px solid lighten($border_lt, 40%)
  border-right 1px solid lighten($border_lt, 40%)
  > form > button
    vertical-align middle
    color #A0A0A0
    line-height $nav_height
    width 20px
    padding-right 10px
    margin-left -5px
  input[type="text"]
    vertical-align middle
    height $nav_height
    width 173px
    border none
    border-radius 0
    font-family inherit
    font-size 14px
    // outline 0
    padding 10px
    -webkit-appearance none
    -webkit-font-smoothing: antialiased;

    &:-ms-input-placeholder
      color $text_med
    &::-moz-placeholder
      color $text_med
    &::-webkit-input-placeholder
      color $text_med
    +below(desktop)
      width 78%
  +below(desktop)
    display block
    background #fff
    text-align left


body.fixed-nav
  padding-top $nav_height
  nav.top-nav
    position fixed

body.course-page nav.top-nav
  position relative

nav
  background #fff
  text-align center
  +above(desktop)
    text-align left
  a.inline
    margin-left 8px
  .nav__button
    overflow visible
    padding 0
    display inline-block
    vertical-align top
    padding-top 5px
    +above(desktop)
      padding-top 13px
  .nav__item
    overflow hidden
    padding 0
    width 25%
    display inline-block
    +above(tablet)
      width auto
      padding-right 20px
    +above(desktop)
      float left
      display block
      width auto
      padding 0
      margin 0 15px
      text-align center
      &:first-child
        margin-left 0

    a
      display inline-block
      text-decoration none
      color $text
      padding-top 15px
      padding-bottom 14px
      border-bottom 3px solid transparent
      +below(tablet)
        padding-top 10px
        padding-bottom 10px
    a.active,
    a:hover
      border-bottom 3px solid $brand_primary
      cursor pointer
    a.active
      color $brand_primary
    &.bordered
      border-left 1px solid manatee
      padding-left 10px
      margin-left 10px

nav.ham-nav
  top 0
  z-index 100
  background-color white
  border-bottom 1px solid rgba(0,0,0,.1)
  overflow visible
  width 100%
  font-size 15px
  -webkit-font-smoothing antialiased
  line-height 1

  +below(desktop)
    position fixed

  .container
    height $nav_height
    display flex
    align-items center
    justify-content space-around

.ham-nav__site-logo
  line-height 0
  a
    display inline-block
    margin 0
    border none

  img
    width 35px
    height 35px

.campaign-nav__wrapper
  height $nav_height
  +below(desktop - 1)
    margin-top 50px

.course-nav__wrapper
  +below(desktop - 1)
    margin-top 50px
.course_navigation,
.campaign_navigation
  background #fff
  box-shadow 0px 1px 0px rgba(0,0,0,.1)
  position relative
  transition box-shadow 125ms ease

  &.affix
    top 0
    z-index 99
    width 100%
    box-shadow 0 0 6px rgba(0,0,0,.2)

  nav
    border none

  > .container
    +above(desktop)
      display flex
    > a
      display block
      text-decoration none
      text-align center
      +above(desktop)
        max-width 290px
        @media only screen and (min-width: 1000px)
          max-width 370px
        margin-right 20px
        padding-right 40px
        text-align left
        border-right 1px solid lighten($border_lt, 20%)
      > h2
        font-size 18px
        font-weight 300
        margin-bottom 0
        white-space nowrap
        text-overflow ellipsis
        line-height 55px
        overflow hidden
        -webkit-font-smoothing auto
  .campaign-nav__ul
    display inline

.campaign-nav__search
  display inline-block

.recent-activity__container nav
  margin-bottom 16px
  border-bottom 1px solid $border_lt

nav.profile-nav
  background-color white
  border-bottom 1px solid rgba(0,0,0,.1)
  overflow hidden
  width 100%
  font-size 15px
  -webkit-font-smoothing antialiased
  line-height 1
  height 45px
  padding-top 12px
  z-index 1

  ul
    list-style none
    margin-bottom 0
    padding 0

    li > a
      text-decoration none
      color inherit
      padding 25px 0 12px 0
      font-size 15px
      &:hover, &.active
        color $brand_primary
        border-bottom 3px solid $brand_primary
.profile-br
  display block
  padding-bottom 40px

#highlight
  display none

.hamburger_menu_wrapper
  width 100%
  height 100%

  /* Position and sizing of burger button */
  .bm-burger-button
    position absolute
    cursor pointer
    width 36px
    height 30px
    right 3%
    top 9px

    .bar1, .bar2, .bar3
      width inherit
      height 5px
      background #3b4175
      transition 0.3s
      margin 6px 0

  .bm-menu-wrap
    position fixed
    top 55px
    width 100% !important
    z-index 1 !important
    height 100%
    right 0px
    text-align left
    // z-index: 10000 !important;
    display none

    &.bm-menu-visible
      display block !important

    .bm-menu
      background #3b4175
      font-size 1.15em
      height 100%

      li
        list-style none
        padding 18px
        border-bottom 1px solid #808080
        margin-left 10px
        margin-right 10px

        a
          display block
          outline none
          text-decoration none
          font-weight 700
          color #fff
        a:hover
          color #D3D3D3
        a:active
          background-color #3b4175 !important

.bm-menu-active .bar1
    -webkit-transform rotate(-45deg) translate(-7.5px, 8px)
    transform rotate(-45deg) translate(-7.5px, 8px)

.bm-menu-active .bar2
  opacity 0

.bm-menu-active .bar3
    -webkit-transform rotate(45deg) translate(-7.5px, -8px)
    transform rotate(45deg) translate(-7.5px, -8px)

@media only screen and (max-width: 920px)
  .top-nav__faq-search
    display block
    background #fff
    text-align left
    input[type="text"]
      width 88%
      outline 0
